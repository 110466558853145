import React from 'react';
import { graphql } from 'gatsby';
import {
  ColorDataTableDesign,
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import contentPreviewData from '../../../../data/previews/content.yml';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Content"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Content" />

      <PageNavigation>
        <PageNavigationLink>Options</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Values</PageNavigationLink>
      </PageNavigation>

      <Section title="Options">
        <Paragraph>
          Choose a content color based on content hierarchy. A clear hierarchy
          makes the interface easier to understand, and ultimately more
          effective.
        </Paragraph>
        <ComponentPreview
          name="contentOptions"
          layout="split"
          previewData={contentPreviewData.options}>
          <Text>We help teams win.</Text>
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Content Hierarchy</SectionSubhead>
        <Paragraph>
          Color contrast isn’t the only way to create content hierarchy.
          Thickness, saturation, whitespace and element size should all be used
          in conjunction with contrast to gracefully guide the user’s attention.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use one color for all elements in a region."
            img="colors-content-hierarchy-dont"
          />
          <DontDoItem
            type="do"
            text="use a variety of content colors and sizes for easier scanning."
            img="colors-content-hierarchy-do"
          />
        </DontDo>

        <SectionSubhead>Accessibility</SectionSubhead>
        <Paragraph>
          Use Contrast, Default and Subtle confidently—they all pass{' '}
          <Link href="https://www.w3.org/TR/WCAG20/">
            WCAG 2.0 contrast accessibility standards
          </Link>{' '}
          on all levels.
        </Paragraph>

        <img
          src={require('../../../../imgs/diagrams/colors-content-contrast.png')}
        />

        <Paragraph>
          Nonessential <em>does not</em> and should never be used for important
          information.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use nonessential for key content in an interface."
            img="colors-content-nonessential-dont"
          />
          <DontDoItem
            type="do"
            text="use nonessential to support the information hierarchy."
            img="colors-content-nonessential-do"
          />
        </DontDo>
      </Section>

      <Section title="Values">
        <Section>
          <SectionSubhead>Light Environment</SectionSubhead>
          <ColorDataTableDesign data={data} colorsType="light" />
        </Section>

        <Section>
          <SectionSubhead>Dark Environment</SectionSubhead>
          <ColorDataTableDesign data={data} env="dark" colorsType="dark" />
        </Section>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query contentColorsDesign {
    allColorsYaml(filter: { type: { eq: "Content" } }) {
      edges {
        node {
          type
          light_environment_colors {
            value
            common_name
          }
          dark_environment_colors {
            value
            common_name
          }
        }
      }
    }
  }
`;
